import {Loader as GoogleMapsLoader} from '@googlemaps/js-api-loader'
import styled from 'styled-components'
import {colors} from '../config/colors.js'
import {zIndex} from '../config/z-index.js'

export const StyledContactSection = styled.section({
  maxWidth: '60rem',
  padding: '0 2rem 4rem 2rem',
  margin: '0 auto',
  textAlign: 'center',

  '.contact__email-and-phone': {
    marginBottom: '2rem',

    a: {
      color: colors['black-#000'],
      '&:hover': {
        backgroundColor: colors['black-#000'],
        color: colors['white-#ddd'],
      },
    },
  },

  '.contact__address': {
    marginBottom: '2rem',

    span: {
      display: 'block',
    },
  },

  ul: {
    listStyleType: 'none',
  },
})

export const StyledGoogleMap = styled.section(props => ({
  height: '75vh',
  width: '100%',
  maxHeight: '60rem',
  maxWidth: '60rem',
  margin: '0 auto',
  // refer to /config/z-index.js for why z-index needs to be set on the Google Map when the mobile nav is open
  zIndex: props.mobileNavOpen ? zIndex.styledGoogleMap : 'unset',
  '@media screen and (min-width: 600px)': {
    marginBottom: '4rem',
  },
}))

export async function loadGoogleMap({apiKey, elementID, markerTitle, markerURL}) {
  try {
    // initialize and load the map
    await new GoogleMapsLoader({
      /**
       * Google Maps API key is public-facing (i.e. available client-side in the browser),
       * but it's restricted to specific HTTP referrers and can only call the Maps JS API
       *
       * https://developers.google.com/maps/documentation/javascript/get-api-key/#restrict_key
       * https://console.developers.google.com/apis/credentials/
      */
      apiKey,
      /**
       * Per Google Maps documentation:
       *   "For most applications, we recommend the weekly channel. This is the most current and
       *   up-to-date version and contains the latest bug fixes and performance improvements."
       *
       * https://developers.google.com/maps/documentation/javascript/versions/#weekly-channel
      */
      version: 'weekly',
    }).load()
  } catch (error) {
    throw error
  }

  // result of the above promise defines the `window.google` variable
  const {google} = window

  const googleMap = new google.maps.Map(
    document.querySelector(`#${elementID}`),
    {
      center: {
        lat: 43.8227468,
        lng: -79.2016777,
      },
      // apply 'subtle grayscale' map styles by Paulo Avila
      // https://snazzymaps.com/style/15/subtle-grayscale/
      styles: [{'featureType':'administrative','elementType':'all','stylers':[{'saturation':'-100'}]},{'featureType':'administrative.province','elementType':'all','stylers':[{'visibility':'off'}]},{'featureType':'landscape','elementType':'all','stylers':[{'saturation':-100},{'lightness':65},{'visibility':'on'}]},{'featureType':'poi','elementType':'all','stylers':[{'saturation':-100},{'lightness':'50'},{'visibility':'simplified'}]},{'featureType':'road','elementType':'all','stylers':[{'saturation':'-100'}]},{'featureType':'road.highway','elementType':'all','stylers':[{'visibility':'simplified'}]},{'featureType':'road.arterial','elementType':'all','stylers':[{'lightness':'30'}]},{'featureType':'road.local','elementType':'all','stylers':[{'lightness':'40'}]},{'featureType':'transit','elementType':'all','stylers':[{'saturation':-100},{'visibility':'simplified'}]},{'featureType':'water','elementType':'geometry','stylers':[{'hue':'#ffff00'},{'lightness':-25},{'saturation':-97}]},{'featureType':'water','elementType':'labels','stylers':[{'lightness':-25},{'saturation':-100}]}],
      zoom: 15,
    }
  )

  new google.maps.Marker({
    icon: markerURL,
    map: googleMap,
    position: new google.maps.LatLng(43.823695, -79.199779),
    title: markerTitle,
  })
}
