import {graphql, StaticQuery} from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Head from '../components/Head/Head.jsx'
import {Heading1} from '../components/Heading/Heading.jsx'
import Page from '../components/Page/Page.jsx'
import {MobileNavOpen} from '../context/mobile-nav-open.js'
import {loadGoogleMap, StyledContactSection, StyledGoogleMap} from '../page-components/contact.js'
import {contentfulImagePropTypes, contentfulMetadataSeoPropTypes} from '../shared-prop-types.js'

const Contact = (props) => {
  const {content, GATSBY_GOOGLE_MAPS_CLIENT_API_KEY} = props

  const googleMapElementID = 'contact__google-map'

  // extracting the Google Map out to a custom hook (i.e. `useGoogleMap`) causes a ReferenceError when the
  // googlemaps/js-api-loader tries to access the not-defined `window` during server-side rendering (SSR);
  // calling `loadGoogleMap` as a side effect ensures that `window` is defined before loading Google Maps
  React.useEffect(() => {
    loadGoogleMap({
      apiKey: GATSBY_GOOGLE_MAPS_CLIENT_API_KEY,
      elementID: googleMapElementID,
      markerTitle: content.address.businessName,
      markerURL: content.googleMapsMarker.file.url,
    })
  })

  return (
    <Page>
      <Head pageContent={content.metadataSEO}/>
      <StyledContactSection>
        <Heading1>{content.metadataSEO.title}</Heading1>
        <div className="contact__email-and-phone">
          <div>
            <a href={`mailto:${content.email}`}>{content.email}</a>
          </div>
          <div>
            <a href={`tel:${content.phone}`}>{content.phone}</a>
          </div>
        </div>
        <div className="contact__address">
          <span>{content.address.businessName}</span>
          <span>{content.address.street}</span>
          <span>{content.address.cityAndProvince}</span>
          <span>{content.address.postalCode}</span>
        </div>
        <ul>
          {content.businessHours.map((schedule) => (
            <li key={schedule.label}>
              {schedule.label}: {schedule.hours}
            </li>
          ))}
        </ul>
      </StyledContactSection>
      <MobileNavOpen.Consumer>
        {({mobileNavOpen}) => <StyledGoogleMap id={googleMapElementID} mobileNavOpen={mobileNavOpen}/>}
      </MobileNavOpen.Consumer>
    </Page>
  )
}

Contact.propTypes = {
  content: PropTypes.shape({
    address: PropTypes.shape({
      businessName: PropTypes.string.isRequired,
      cityAndProvince: PropTypes.string.isRequired,
      postalCode: PropTypes.string.isRequired,
      street: PropTypes.string.isRequired,
    }).isRequired,
    businessHours: PropTypes.arrayOf(
      PropTypes.shape({
        hours: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
    email: PropTypes.string.isRequired,
    googleMapsMarker: PropTypes.shape(contentfulImagePropTypes).isRequired,
    metadataSEO: PropTypes.shape(contentfulMetadataSeoPropTypes).isRequired,
    phone: PropTypes.string.isRequired,
  }).isRequired,
  GATSBY_GOOGLE_MAPS_CLIENT_API_KEY: PropTypes.string.isRequired,
}

const ContactWithContent = () => (
  <StaticQuery
    // to test queries, refer to the dev server terminal output for the GraphQL URL
    query={graphql`
      query ContentfulPageContactQuery {
        contentfulPageContact {
          address {
            businessName
            cityAndProvince
            postalCode
            street
          }
          businessHours {
            hours
            label
          }
          email
          googleMapsMarker {
            file {
              url
            }
          }
          metadataSEO {
            canonicalURL
            description
            title
          }
          phone
        }
      }
    `}
    render={data => (
      <Contact
        content={data.contentfulPageContact}
        /**
         * environment variables that need to made accessible in the browser/
         * client-side JavaScript must use the `GATSBY_` prefix in their name
         *
         * https://www.gatsbyjs.com/docs/how-to/local-development/environment-variables/#client-side-javascript
         * https://community.netlify.com/t/api-keys-and-environment-variables-on-netlify/3865/
        */
        GATSBY_GOOGLE_MAPS_CLIENT_API_KEY={process.env.GATSBY_GOOGLE_MAPS_CLIENT_API_KEY}
      />
    )}
  />
)

export default ContactWithContent
